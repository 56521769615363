@import 'styles/new-variables';

.tooltip {
  @apply font-brand-b3;
  pointer-events: none;
  border-radius: 6px;
  padding: 8px 16px;
  box-shadow: 2px 0 8px #0002;
  z-index: 9999;
  max-width: 360px;
  word-wrap: break-word;
}

.tooltip.white {
  background: $text-100;
  color: $text-400;
  border: 1px solid $other-400;
}

.tooltip.grey {
  background: $other-100;
  color: $text-400;
  border: 1px solid $other-400;
}

.tooltip.darkgrey {
  background: $other-600;
  color: $text-100;
}

.tooltip.primary {
  background: $text-600;
  color: $text-100;
}

.arrow path:first-of-type {
  stroke-width: 2px !important;
  stroke: inherit !important;
}
.arrow path:last-of-type {
  stroke: transparent !important;
}

.white.arrow {
  fill: $text-100;
  stroke: $other-400;
}

.grey.arrow {
  fill: $other-100;
  stroke: $other-400;
}

.darkgrey.arrow {
  fill: $other-600;
}

.primary.arrow {
  fill: $text-600;
}
