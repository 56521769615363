@import 'styles/new-variables';
@import '~styles/import';

.backdrop {
  background: #0007;
  position: relative;
  overflow-y: auto;
  height: 100%;
  display: grid;
  z-index: 50;
  place-items: center;

  @include sm {
    place-items: end;
  }
}

.backdrop[data-status='initial'] {
  opacity: 0;
}

.backdrop[data-status='open'] {
  opacity: 1;
  transition: all .3s ease-out;
}

.backdrop[data-status='close'] {
  opacity: 0;
  transition: all .1s ease-in;
}

.modal {
  margin: 32px auto;
  width: fit-content;
  max-width: 100%;
  background: $text-100;
  border-radius: 6px;
  box-shadow: 2px 0 6px #0005;
  // for close button absolute positioning
  position: relative;

  @include sm {
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
  }

  & > * {
    max-width: 100vw;
  }
}

.modal[data-status='initial'] {
  opacity: 0;
  transform: translateY(-24px);

  @include sm {
    transform: translateY(72px);
  }
}

.modal[data-status='open'] {
  opacity: 1;
  transform: translateY(0);
  transition: all .3s ease-out;
  transition-property: opacity, transform;
}

.modal[data-status='close'] {
  opacity: 0;
  transform: translateY(-20px);
  transition: all .1s ease-in;
  transition-property: opacity, transform;

  @include sm {
    transform: translateY(72px);
  }
}

