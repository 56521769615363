@import 'styles/new-variables';

// Sizes
.small {
  padding: 1px 6px;
}

.medium {
  padding: 2px 8px;
}

// Colors
.primary {
  color: $primary-300;
  background-color: $primary-100;
}

.secondary {
  color: $secondary-300;
  background-color: $secondary-100;
}

.other {
  color: $other-500;
  background-color: $other-100;
}

.success {
  color: $success-300;
  background-color: $success-100;
}

.info {
  color: $info-300;
  background-color: $info-100;
}

.warning {
  color: $warning-300;
  background-color: $warning-100;
}

.error {
  color: $error-300;
  background-color: $error-100;
}

