@import 'styles/new-variables';

.success {
  color: $secondary-400;
  background-color: $secondary-100;
  border-color: $secondary-200;
}

.info {
  color: $info-300;
  background-color: $info-100;
  border-color: $info-200;
}

.warning {
  color: $warning-300;
  background-color: $warning-100;
  border-color: $warning-200;
}

.error {
  color: $error-300;
  background-color: $error-100;
  border-color: $error-200;
}