@import 'mixins';

$primary-400: #00308d;
$primary-300: #2856ae;
$primary-200: #9fc3ee;
$primary-100: #f0f6fc;

$secondary-400: #008970;
$secondary-300: #3ebf8a;
$secondary-200: #a0e2c6;
$secondary-100: #f0fbfa;

$success-300: #008970;
$success-200: #a0e2c6;
$success-100: #f0fbfa;

$warning-300: #d88e00;
$warning-200: #f8da8e;
$warning-100: #fffbe4;

$error-300: #e1485b;
$error-200: #f8bcbf;
$error-100: #fff4f5;

$info-300: #594bb5;
$info-200: #cfc7f0;
$info-100: #f2efff;

$text-700: #131831;
$text-600: #1e2860;
$text-500: #2b3151;
$text-400: #697082;
$text-300: #8c93a3;
$text-200: #bec5d0;
$text-100: #ffffff;

$other-600: #929bad;
$other-500: #b0baca;
$other-400: #cdd7e5;
$other-300: #e2e9f3;
$other-200: #eaf0f7;
$other-100: #f6f8fc;
$other-50: #fcfdff;

@mixin font-brand-ph {
  font-size: 16px;
  line-height: 19px;
}

@mixin font-brand-o1 {
  font-size: 12px;
  line-height: 15px;
}

@mixin font-brand-t1 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
}

@mixin font-brand-t2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

@mixin font-brand-t3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

@mixin font-brand-t4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

/**
 * Usage:
 *
 * @include box(24) => { width: 24px; height: 24px };
 * @include box(24, 48) => { width: 24px; height: 48px };
 */
@mixin box($width, $height: $width) {
  min-width: $width * 1px;
  min-height: $height * 1px;
  width: $width * 1px;
  height: $height * 1px;
}

@mixin center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin transition($property: all, $duration: 300ms, $function: ease) {
  transition: $property $duration $function;
}
