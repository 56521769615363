.floating {
  transition-property: opacity;
}

.floating[data-status='initial'] {
  opacity: 0;
}

.floating[data-status='open'] {
  opacity: 1;
  transition-duration: 250ms;
}

.floating[data-status='close'] {
  opacity: 0;
  transition-duration: 75ms;
}

.floating[data-instant="true"] {
  transition-duration: 1ms !important;
}
