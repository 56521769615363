@import '~styles/variables';
@import '~styles/mixins';


.billing-overview__loading {
  width: 100%;

  @include ie() {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .ie-loader {
    display: none;

    @include ie() {
      display: inline-block;
    }
  }


  .billing-overview-loader {

    @include ie() {
      display: none;
    }

    .table-section {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      padding: 24px 16px 20px;
      border-radius: 4px;
      border: solid 1px #ebedf8;
      background-color: #ffffff;
    }
  }
}
