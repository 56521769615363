* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
  outline: none;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

.BeaconFabButtonFrame {
  z-index: 8 !important;
}
