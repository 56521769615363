* {
  scrollbar-width: thin;
  scrollbar-color: $other-300 transparent;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin-block: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: $other-300;
  border-radius: 9999px;
  border: 1px solid $text-100;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $other-400;
}

