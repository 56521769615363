@import '~styles/_mixins.scss';

.holiday {
  @include adaptive(mobile) {
    padding: 20px
  }

  & &__dialog {
    margin: 5px;
    max-width: 767px;
    width: 100%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4a4a4a;
    background-color: white;
    border-radius: 5px;
  }

  &__title {
    max-width: 100%; // IE
    text-align: center;
    font-size: 28px;

    @include max-adaptive(767px) {
      br {
        display: none;
      }
    }

    @include adaptive(mobile) {
      font-size: 22px;
    }
  }

  &__image {
    margin-top: 15px;
    max-width: 666px;
    width: 100%;
    min-height: 1px; // IE
  }

  &__content {
    max-width: 100%; // IE
    width: 100%;
  }

  p {
    margin-top: 15px;
    max-width: 100%; // IE
    font-size: 16px;
    line-height: 22px;

    @include adaptive(mobile) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
