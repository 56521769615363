@import '~styles/variables';
@import '~styles/mixins';

.online-subscriptions,
.os {
  &__dialog {
    overflow: visible;
    max-width: 766px;

    @include adaptive(tablet) {
      max-width: 688px;
    }
  }

  .online-subscriptions__modal {
    text-align: center;
    padding: 32px 16px 73px;

    @include adaptive(tablet) {
      padding: 40px 40px 48px;
    }

    @include adaptive(desktop) {
      padding: 40px 24px 48px;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 24px;

    @include min-adaptive(tablet) {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 16px;
    }
  }

  &__title .gradient {
    display: block;
    background: linear-gradient(45deg, #0b84c3 0, #46b688 100%);
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include ie() {
      background: none;
    }

    @include min-adaptive(desktop) {
      display: inline;
    }
  }

  &__subtitle {
    margin-bottom: 20px;

    @include min-adaptive(tablet) {
      font-size: 16px;
      line-height: 22px;
      width: 496px;
      margin: 0 auto 13px;
    }
  }

  &__benefits {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 48px;

    @include min-adaptive(tablet) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    @include min-adaptive(desktop) {
      margin-bottom: 26px;
    }
  }

  &__benefits ul {
    max-width: 480px;
    margin: 0 auto;

    @include min-adaptive(tablet) {
      max-width: none;
      margin: 0;
      margin-right: 45px;

      // for IE
      width: 50%;
    }

    @include min-adaptive(desktop) {
      max-width: 324px;
    }
  }

  &__benefits ul li {
    display: flex;
    margin-bottom: 16px;

    @include min-adaptive(tablet) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__benefits ul li img {
    align-self: center;
    width: 16px;
    height: 15px;
    margin-right: 16px;

    // for IE
    min-width: 16px;
    min-height: 15px;
  }

  &__benefits ul li p {
    text-align: left;
    font-size: 13px;
    line-height: 18px;

    @include min-adaptive(tablet) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 320px;
    margin: 0px auto 50px;

    @include min-adaptive(tablet) {
      flex-wrap: nowrap;
      max-width: none;
    }
  }

  &__partners img {
    width: calc(33% - 16px);
    height: 37px;
    margin: 0 8px;

    // hide 7th logo on tablet and mobile
    @include max-adaptive(tablet) {
      &:nth-child(n + 7) {
        display: none;
      }
    }

    @include min-adaptive(tablet) {
      // width 100% / 6 elements - 1 row
      width: calc(16.6% - 16px);
      margin: 0;
    }

    @include min-adaptive(desktop) {
      width: calc(16.6% - 40px);
    }
  }

  &__button {
    width: 177px;
    margin: 0 auto;

    @include min-adaptive(tablet) {
      width: 209px;
    }
  }
}
