.boundary-box,
.bb {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;

  &-16 {
    @extend .boundary-box;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &-20 {
    @extend .boundary-box;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &-32 {
    @extend .boundary-box;
    width: 32px;
    height: 32px;
  }

  &-40 {
    @extend .boundary-box;
    width: 40px;
    height: 40px;
  }

  &-80 {
    @extend .boundary-box;
    width: 80px;
    height: 80px;
  }
}

.size {
  &-24 {
    width: 24px;
    height: 24px;
  }

  &-28 {
    width: 28px;
    height: 28px;
  }

  &-48 {
    width: 48px;
    height: 48px;
  }
}

// todo: move it to position helpers
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.full-width {
  width: 100%;

  &-desktop {
    @include adaptive(desktop) {
      width: 100%;
    }
  }

  &-tablet {
    @include adaptive(tablet) {
      width: 100%;
    }
  }

  &-mobile {
    @include adaptive(mobile) {
      width: 100%;
    }
  }
}

.height-100 {
  height: 100%;
}

.block {
  display: block;
}

.inline-block,
.ib {
  display: inline-block;
}

.inline {
  display: inline;
}

.none {
  display: none;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

.bold,
.fw-700 {
  font-weight: 700;
}

.semibold,
.fw-600 {
  font-weight: 600;
}

.normal,
.fw-400 {
  font-weight: 400;
}

.online-status {
  /* Status is "offline" by default */
  display: inline-flex;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(#bdbdbd, 0.3); // todo: add variable color
  background-color: rgba(#bdbdbd, 0.3); // todo: add variable color

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #bdbdbd; // todo: add variable color
  }

  &--online {
    box-shadow: 0 0 0 0 rgba($color-mint, 0.3);
    background-color: rgba($color-mint, 0.3);

    /* Animation example */
    /* animation: pulse 3s infinite cubic-bezier(0.66, 0, 0, 50); */

    &:before {
      background-color: $color-mint;
    }
  }
}

.uppercase,
.upper {
  text-transform: uppercase;
}

.lowercase,
.lower {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.tooltip {
  $tooltip-bg: rgba(0, 0, 0, 0.75);

  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  padding: 12px 8px;
  border-radius: 4px;
  color: #fff;
  background-color: #000; // IE fallback
  background-color: $tooltip-bg;
  z-index: 2;
  pointer-events: none;

  &--top {
    bottom: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: solid transparent;
      border-top-color: $tooltip-bg;
      border-width: 6px;
      pointer-events: none;
    }
  }

  &--bottom {
    top: 100%;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: solid transparent;
      border-bottom-color: $tooltip-bg;
      border-width: 6px;
      pointer-events: none;
    }
  }
}

.close-icon {
  width: 12px;
}

.min-width-0 {
  /* Flex column container with .text-overflow fix */
  min-width: 0;
}

.font-size-0,
.fs-0 {
  font-size: 0;
}

.max-width-100,
.mw-100 {
  max-width: 100%;
}

.opacity-1 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.label {
  $label-opacity: 0.15;

  &--blue {
    color: $color-blumine;
    background-color: rgba($color-lochmara, $label-opacity);
  }

  &--green {
    color: $color-salem;
    background-color: rgba($color-mint, $label-opacity);
  }

  &--red {
    color: $color-darkpastelred;
    background-color: rgba($color-terracotta, $label-opacity);
  }

  &--orange {
    color: $orange-primary;
    background-color: $orange-background;
  }

  &-solid--blue {
    background-color: $color-lochmara;
  }

  &-solid--green {
    background-color: $color-mint;
  }

  &-solid--red {
    background-color: $color-terracotta;
  }

  &-solid--orange {
    background-color: $orange-background;
  }
}

.select-control > .clear {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 6px;
  z-index: 1;
  background-color: #fff;
  cursor: pointer;
}

.underline {
  text-decoration: underline;

  &-hover:hover {
    text-decoration: underline;
  }
}

/* Julia's button text design system */
.button-text {
  &--blue {
    color: $blue-primary;
  }

  &--blue:hover {
    color: $blue-hover;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-hidden {
  overflow: hidden;
}

.white-space,
.ws {
  &-pre {
    white-space: pre;
  }

  &-pre-wrap {
    white-space: pre-wrap;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.country-value-container {
  max-width: 85%; // IE Fallback
  max-width: calc(100% - 36px); // Dropdown arrow substriction
}

.rotate-180 {
  transform: rotate(180deg);
}
