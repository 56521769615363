.pri400, .primary-400 { color: $primary-400; }
.pri300, .primary-300 { color: $primary-300; }
.pri200, .primary-200 { color: $primary-200; }
.pri100, .primary-100 { color: $primary-100; }

.sec400, .secondary-400 { color: $secondary-400; }
.sec300, .secondary-300 { color: $secondary-300; }
.sec200, .secondary-200 { color: $secondary-200; }
.sec100, .secondary-100 { color: $secondary-100; }

.suc300, .success-300 { color: $success-300; }
.suc200, .success-200 { color: $success-200; }
.suc100, .success-100 { color: $success-100; }

.war300, .warning-300 { color: $warning-300; }
.war200, .warning-200 { color: $warning-200; }
.war100, .warning-100 { color: $warning-100; }

.err300, .error-300 { color: $error-300; }
.err200, .error-200 { color: $error-200; }
.err100, .error-100 { color: $error-100; }

.inf300, .info-300 { color: $info-300; }
.inf200, .info-200 { color: $info-200; }
.inf100, .info-100 { color: $info-100; }

.tex700, .text-700 { color: $text-700; }
.tex600, .text-600 { color: $text-600; }
.tex500, .text-500 { color: $text-500; }
.tex400, .text-400 { color: $text-400; }
.tex300, .text-300 { color: $text-300; }
.tex200, .text-200 { color: $text-200; }
.tex100, .text-100 { color: $text-100; }

.oth600, .other-600 { color: $other-600; }
.oth500, .other-500 { color: $other-500; }
.oth400, .other-400 { color: $other-400; }
.oth300, .other-300 { color: $other-300; }
.oth200, .other-200 { color: $other-200; }
.oth100, .other-100 { color: $other-100; }

//
// Outdated
//
/* Greyscale */
.color-jaguar, .jaguar { color: $color-jaguar !important; } // #2A2A2A
.color-charcoal, .charcoal { color: $color-charcoal !important; } // #454545
.color-graysuit, .graysuit { color: $color-graysuit !important; } // #9398a2
.color-alabaster, .alabaster { color: $color-alabaster !important; } // #ebedf8
.color-athensgray, .athensgray { color: $color-athensgray !important; } // #fafbfc

/* Green */
.color-salem, .salem { color: $color-salem !important; } // #1f7753
.color-mint, .mint { color: $color-mint !important; } // #46b688

/* Blue */
.color-pearl, .pearl { color: $color-pearl !important; } // #1b2532 // 
.color-blumine, .blumine { color: $color-blumine !important; } // #095c88
.color-lochmara, .lochmara { color: $color-lochmara !important; } // #007ac5
.color-zumthor, .zumthor { color: $color-zumthor !important; } // #eef6fb

/* Red */
.color-darkpastelred, .darkpastelred { color: $color-darkpastelred !important; } // #bf3e29
.color-terracotta, .terracotta { color: $color-terracotta !important; } // #ed705c

