@import 'styles/new-variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  color: $text-500;
  user-select: none;
  cursor: pointer;
}

.checkmark {
  color: $other-500;
  font-size: 21px;
  width: 24px;
  height: 24px;
}

.checkmark + .text {
  margin-left: 8px;
}

.checked {
  .checkmark {
    color: $primary-300;
  }
}

.disabled {
  .checkmark {
    color: $other-500;
  }

  .text {
    color: $text-300;
  }
}

.checked.disabled {
  .checkmark {
    color: $other-500;
  }

  .text {
    color: $text-400;
  }
}
