@import 'styles/new-variables';

.wrapper {
  display: block;
  width: 100%;
  pointer-events: none;
  margin: 0;
}

.inputWrapper {
  display: flex;
  align-items: center;
  pointer-events: auto;
  position: relative;
  color: $text-500;
  border: 1px solid $other-400;
  border-radius: 4px;
  background-color: $other-50;
  @include transition();
}

.s {
  @apply font-brand-sm;
  height: 32px;

  .icon {
    @include box(32);
  }

  .children {
    max-height: 32px;

    & > * {
      @include box(32);
    }
  }
}

.m {
  @apply font-brand-b3;
  height: 40px;

  .icon {
    @include box(40);
  }

  .children {
    max-height: 32px;

    & > * {
      @include box(32);
    }
  }
}

.l {
  @apply font-brand-b3;
  height: 48px;

  .icon {
    @include box(48);
  }

  .children {
    max-height: 32px;

    & > * {
      @include box(32);
    }
  }
}

.icon {
  @include center;

  & + .input {
    padding-left: 0;
  }
}

.input {
  font-size: inherit;
  padding: 0 8px;
  width: 100%;
  background-color: transparent;

  &::placeholder {
    color: $text-300;
  }

  &:autofill, &:-internal-autofill-selected {
    background-color: transparent !important;
  }
}

.children {
  @include center;

  & > * {
    @include center;
  }
}

.error {
  .inputWrapper {
    background-color: $error-100;
    border-color: $error-300;
    color: $text-500;
  }
}

.wrapper:focus-within .inputWrapper {
  border-color: $primary-200;
  background-color: $primary-100;
  color: $text-500;
}

.readOnly {
  .input {
    cursor: pointer;
  }

  .input::selection {
    background-color: transparent;
    color: inherit;
  }
}

.titleWrapper {
  display: flex;
  margin-bottom: 8px;
  pointer-events: none;

  &.disabled {
    color: $text-200;
  }

  .titleIcon {
    color: $text-300;
    pointer-events: auto;
    display: inline-flex !important;
    margin-left: 4px;
    vertical-align: middle;
  }

  .hintTooltip {
    max-width: 230px;
    padding: 8px 12px;
  }
}

.disabled {
  .inputWrapper {
    color: $text-200;
    border-color: $other-300;
    background-color: $other-100;
    cursor: not-allowed;
  }

  .input {
    cursor: not-allowed;
  }

  .input::placeholder {
    color: $text-200;
  }

  .input::selection {
    background-color: transparent;
    color: inherit;
  }

  .children > * {
    pointer-events: none;
  }
}

// Fix select showing wrong selected item when opening from unfocused state using chevron
.children > [class*="chevron"] {
  pointer-events: none;
}

.wrapper:focus-within .children > [class*="chevron"] {
  pointer-events: auto;
}

.errors {
  @apply font-brand-sm;
  display: block;
  color: $error-300;
  text-align: right;
}

.multipleErrors {
  display: flex;
  justify-content: flex-end;

  .errors {
    margin-left: 12px;
    text-align: left;
  }

  li {
    list-style: disc;
  }
}
