@import '~styles/variables';
@import '~styles/mixins';

.could-save,
.cs {
  &__dialog {
    overflow: visible;
    max-width: 832px;
  }

  .could-save__modal {
    text-align: center;

    @include md-lg {
      padding: 40px 48px;
    }

    @include sm {
      padding: 40px 16px;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    em {
      font-style: normal;
      color: $secondary-300;
    }

    br {
      @include sm {
        display: none;
      }
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  &__content {
    color: $text-500;
  }

  &__subtitle span {
    display: inline-block;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    background: #ed715c;
    color: #fff;
    border-radius: 3px;
    padding: 2px 9px;
    margin: 0 7px;
  }

  &__benefits {
    @include md-lg {
      margin-bottom: 48px;
    }
  }

  &__benefits-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    color: #5d5d5d;
    margin-bottom: 32px;
  }

  &__benefits ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__benefits ul li img {
    margin-bottom: 16px;
    height: 128px;
  }

  &__benefits ul li {
    margin: 0 auto;
    font-size: 18px;
    line-height: 25px;

    @include md-lg {
      min-width: 180px;
      width: calc(33% - 24px);
    }

    @include sm {
      max-width: 240px;
      margin-bottom: 24px;
    }
  }

  &__benefits p {
    font-size: 16px;
    line-height: 26px;
  }

  &__proposal {
    margin: 0 auto 24px;
    font-size: 18px;
  }

  &__proposal span {
    margin: 0 5px;
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: $secondary-300;
  }

  &__button {
    display: inline-flex;
    margin: 0 auto;
  }

  &__dont-show {
    margin-top: 24px;
    padding: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $text-300;
  }
}
