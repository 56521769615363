.flex,
.f {
  display: flex;

  &-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &-0 {
    flex: 0;
  }

  &-1 {
    flex: 1;
  }

  &-auto {
    flex: auto;
  }

  &-grow-1 {
    flex-grow: 1;
  }

  &-terminal {
    flex: 1 0 auto;
  }

  &-basis-100 {
    flex-basis: 100%;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-row {
    display: flex;
    flex-direction: row;
  }

  &-shrink-0 {
    flex-shrink: 0;
  }

  &-center-base {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  &-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &-center-center,
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-center-start {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  &-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-between-end {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &-justify-between {
    display: flex;
    justify-content: space-between;
  }

  &-justify-around {
    display: flex;
    justify-content: space-around;
  }

  &-justify-start {
    display: flex;
    justify-content: flex-start;
  }

  &-justify-center {
    display: flex;
    justify-content: center;
  }

  &-justify-end {
    display: flex;
    justify-content: flex-end;
  }

  &-align-start {
    display: flex;
    align-items: flex-start;
  }

  &-align-center {
    display: flex;
    align-items: center;
  }

  &-align-end {
    display: flex;
    align-items: flex-end;
  }

  &-align-stretch {
    display: flex;
    align-items: stretch;
  }
}
