.modal-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1401; // Because step-toolbar `z-index` is `1400`

  &.darker {
    background: rgba(0, 0, 0, 0.25);
  }

  &.lighter {
    background: rgba(255, 255, 255, 0.25);
  }

  &.dark {
    background: rgba(0, 0, 0, 1);
  }

  &.light {
    background: rgba(255, 255, 255, 1);
  }

  &.fixed .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}