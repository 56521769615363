@import 'src/styles/_new-variables';
@import 'src/styles/_mixins';

.paginationItem {
  @apply font-brand-l3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  min-width: 32px;
  padding: 0 11px;
  color: $text-300;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
  @include transition();

  &:hover {
    text-decoration: none;
    color: $text-400;
  }

  &:hover:not(.disabled) {
    background-color: $other-100;
    cursor: pointer;
  }

  &.active {
    color: $primary-300;
    border-color: $primary-300;
    background-color: $other-100;
  }

  &.icon {
    font-size: 11px;
  }

  &.disabled {
    color: $text-200;
  }
}
