@import '~styles/new-variables';

.status { 
  position: absolute;
  bottom: -2px;
  left: -2px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
}