@import 'src/styles/_new-variables';

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  width: 40px;
  @apply font-brand-b2
}

.hoverable {
  border: 1px solid transparent;
  border-radius: 9999px;
  transition: all .15s ease;
}

.hoverable:hover {
  border-color: $primary-300;
}

.prevNext {
  height: 40px;
  width: 40px;
  color: $text-500;
  border-radius: 9999px;
}

.prevNext:disabled {
  border: 0;
  color: $text-300;
}

.rangeDay::after {
  display: flex;
  justify-content: center;
  align-items: center;
  counter-reset: content var(--content);
  content: counter(content);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 9999px;
  transition: all .3s ease;
}

.rangeDay::after:hover {
  border-color: $primary-300;
}

.rangeDaySelected::after {
  color: white;
  background: $primary-300;
}

.rangeDaySelected {
  background: $warning-100;
}

.fill {
  background: $other-100;
}
