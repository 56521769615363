.drop-zone {

  .upload-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    border: 2px dashed #bdbdbd;
    max-width: 756px;
    width: 100%;
    height: 180px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    background-color: #fafbfc;
    transition: all .2s;

    &--disabled {
      cursor: not-allowed;
    }

    &--active {
      border: solid 2px #0a7abf;
      background-color: #e8f4fa;
    }

    &--error {
      background-color: #fef8f7;
      border: solid 2px #ed715c;
    }
  }

  .upload-button {
    position: relative;
    width: 30px;
    height: 30px;
    background-color: #2D80CA;
    border-radius: 15px;
    cursor: pointer;

    &--error {
      background-color: #f46775;
    }

    &__icon {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 12px;
    }
  }

  .upload-description {
    padding-top: 20px;
    text-align: center;
    font-size: 1em;
    line-height: 1.29;
    letter-spacing: -0.18px;
    color: #4a4a4a;
    font-weight: 400;
  }

  .uploaded-files {
    display: flex;
    margin-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 756px;
    font-size: 0.857em;

    &__row, &__row--rejected {
      display: flex;
      padding: 9px;
      margin-bottom: 10px;
      width: 100%;
      border-radius: 3px;
      border: solid 1px #ebedf8;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      color: #454545;

      &--rejected {
        background-color: #fef8f7;
      }

      .upload-file-name {
        display: flex;
        align-items: center;

        span {
          text-align: left;
          margin-left: 7px;
          width: 100%;
        }
      }

      .upload-progress {
        width: 35%;
        height: 6px;
        border-radius: 3px;
        background-color: #ebedf8;

        & > div {
          border-radius: 3px;
          background-color: #46b688;
        }
      }

      .upload-status {
        color: #46b688;
        margin-left: auto;
        margin-right: 16px;

        &--rejected {
          color: #ed715c;
        }
      }

      .upload-remove-file {
        width: 5%;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .upload-file-box {
    &__drop-zone {
      .upload-box {
        flex-direction: row;
      }

      .upload-description {
        padding-top: 0;
        margin-left: 20px;
      }
    }
  }
}
