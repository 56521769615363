.text-link-24slides {
  &--dark {
    color: #0b84c3;
    cursor: pointer;

    &:hover {
      color: #0b84c3;
      text-decoration: underline;
    }

    &:visited {
      color: #0a5a90;
      text-decoration: none;
    }

    &:visited:hover {
      color: #0a5a90;
      text-decoration: underline;
    }
  }

  &--light {
    color: #ffffff;
    cursor: pointer;

    &:hover {
      color: #ffffff;
      text-decoration: underline;
    }

    &:visited {
      color: #ececec;
    }
  }
}
