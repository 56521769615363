.display,
.d {
  &-block {
    display: block;
  }

  &-inline-block {
    display: inline-block;
  }

  &-inline {
    display: inline;
  }

  &-none {
    display: none;
  }

  &-inline-flex {
    display: inline-flex;
  }

  &-grid {
    display: grid;
  }
}
