@supports not (height: 100dvh) {
  html {
    --dvh: 100vh; /* Fallback */
  }
}

@supports (height: 100dvh) {
  html {
    --dvh: 100dvh; /* Use native dvh if supported */
  }
}

body {
  height: var(--dvh);
}
