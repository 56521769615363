@import 'styles/new-variables';

.divider {
  font-size: 18px;
  line-height: 19px;
  color: $text-300;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40%;
    height: 1px;
    border-radius: 1px;
    background-color: $other-300;
    transform: translateY(1px);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
}
