@import 'styles/new-variables';

$option-height: 36px;

.container {
  background-color: $text-100;
  border-radius: 6px;
  border: 1px solid $other-300;
  box-shadow: 2px 0 8px #0002;
  max-height: $option-height * 4 + 2;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 51;
}

.options {
  margin: 0;
}

.option {
  display: flex;
  margin: 0;
  width: var(--reference-width);
  align-items: center;
  padding: 0 8px;
  line-height: $option-height;
  height: $option-height;
  color: $text-500;
  cursor: pointer;
  user-select: none;
}

.option:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.option:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.optionActive {
  color: $primary-300;
  background-color: $primary-100;
}

.notFound {
  composes: option;
  border-radius: 6px;
  white-space: nowrap;
  width: min-content;
}

.chevron {
  cursor: pointer;
  transform: rotate(-180deg);
  width: 16px;
  @include transition();
}

.chevronActive {
  transform: rotate(0);
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition: 0.2s ease opacity;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
}
