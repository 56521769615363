@import '~styles/variables';
@import '~styles/mixins';

.book-call {
  position: relative;
  margin-left: 16px;

  &__icon {
    margin-right: 4px;
  }

  .notification {
    position: absolute;
    top: 100%;
    left: -100%;
    width: 224px;
    text-align: left;
    transform: translateY(16px);
  }
}
