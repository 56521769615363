/**
 * Outdated mixins for compatibility
 */

@mixin adaptive($device) {
  @if $device == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $device == tablet {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $device == desktop {
    @media (min-width: 992px) {
      @content;
    }
  }
}

@mixin min-adaptive($size) {
  @if $size == tablet {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size == desktop {
    @media (min-width: 992px) {
      @content;
    }
  } @else {
    @media (min-width: $size) {
      @content;
    }
  }
}

@mixin max-adaptive($size) {
  @if $size == mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == tablet {
    @media (max-width: 991px) {
      @content;
    }
  } @else {
    @media (max-width: $size) {
      @content;
    }
  }
}

@mixin ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin safari() {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      @content;
    }
  }
}

@mixin font-style($font-name, $path, $style: normal, $weight: normal) {
  @font-face {
    font-family: quote($font-name);
    font-style: $style;
    font-weight: $weight;
    src: url('#{$path}.woff2') format('woff2'), url('#{$path}.woff') format('woff'),
      url('#{$path}.ttf') format('truetype');
  }
}

@mixin transition($property: all, $duration: 0.25s, $function: ease, $delay: 0s) {
  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $function;
  transition-delay: $delay;
}
