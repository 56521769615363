.box {
  &-16 {
    @include box(16);
  }

  &-24 {
    @include box(24);
  }

  &-32 {
    @include box(32);
  }

  &-40 {
    @include box(40);
  }

  &-48 {
    @include box(48);
  }
}
