//
// Font family
//

$font-primary: 'Inter';
$font-path: '../fonts';

//
// Media breakpoints
//

$mobile: 767px;
$tablet: 1200px;

//
// Color system
//

//
// Greyscale
//
$color-storm: #697082;
$color-manatee: #8c93a3;
$color-rock: #929bad;
$color-spindle: #b0baca;
$color-loblolly: #bec5d0;
$color-hawkes: #cdd7e5;
$color-solitude: #e2e9f3;
$color-romance: #fcfdff;
$color-athens: #eaf0f7;
$color-alice: #f6f8fc;
$color-white: #ffffff;

// Outdated Greyscale
$color-black: #000000;
$color-jaguar: #2a2a2a;
$color-charcoal: #454545;
$color-graysuit: #9398a2;
$color-athensgray: #ebedf8;
$color-alabaster: #fafbfc;

//
// Green
//
$color-observatory: #008970;
$color-shamrock: #3ebf8a;
$color-riptide: #a0e2c6;
$color-spring: #f0fbfa;

// Outdated Green
$color-catskill: #e9f6f0;
$color-aquamarine: #80dcb5;
$color-mint: #46b688;
$color-gossamer: #399f81;
$color-salem: #1f7753;

//
// Blue
//
$color-mirage: #131831;
$color-biscay: #1e2860;
$color-luckypoint: #2b3151;
$color-cobalt: #00308d;
$color-cerulean: #2856ae;
$color-denim: #0a66bb;
$color-cornflower: #9fc3ee;
$color-zumthor: #f0f6fc;

// Outdated Blue
$color-pearl: #1b2532;
$color-blumine: #095c88;
$color-lochmara: #007ac5;
$color-zumthor-outdated: #eef6fb;

//
// Purple
//
$color-violet: #594bb5;
$color-perfume: #cfc7f0;
$color-magnolia: #f2efff;

//
// Red
//
$color-mandy: #e1485b;
$color-azalea: #f8bcbf;
$color-chablis: #fff4f5;

// Outdated red
$color-darkpastelred: #bf3e29;
$color-terracotta: #ed705c;

//
// Orange
//
$color-gamboge: #d88e00;
$color-visvis: #f8da8e;
$color-fizz: #fffbe4;

//
// Design system
//
$primary-400: $color-cobalt;
$primary-300: $color-cerulean;
$primary-200: $color-cornflower;
$primary-100: $color-zumthor;

$secondary-400: $color-observatory;
$secondary-300: $color-shamrock;
$secondary-200: $color-riptide;
$secondary-100: $color-spring;

$success-300: $color-observatory;
$success-200: $color-riptide;
$success-100: $color-spring;

$warning-300: $color-gamboge;
$warning-200: $color-visvis;
$warning-100: $color-fizz;

$error-300: $color-mandy;
$error-200: $color-azalea;
$error-100: $color-chablis;

$info-300: $color-violet;
$info-200: $color-perfume;
$info-100: $color-magnolia;

$text-700: $color-mirage;
$text-600: $color-biscay;
$text-500: $color-luckypoint;
$text-400: $color-storm;
$text-300: $color-manatee;
$text-200: $color-loblolly;
$text-100: $color-white;

$other-600: $color-rock;
$other-500: $color-spindle;
$other-400: $color-hawkes;
$other-300: $color-solitude;
$other-200: $color-athens;
$other-100: $color-alice;
$other-50: $color-romance;

//
// Outdated design system
//

// Green
$green-background: #e9f6f0;
$green-light: #80dcb5;
$green-primary: #46b688;
$green-hover: #399f81;
$green-dark: #1f7753;

// Blue
$blue-background: #eaf4fa;
$blue-light: #61bbea;
$blue-primary: #0b84c3;
$blue-hover: #0a5a90;
$blue-dark: #095c88;

// Orange
$orange-background: #fdece8;
$orange-light: #f18060;
$orange-primary: #ed715c;
$orange-hover: #d46b5d;
$orange-dark: #ae5352;

// Text
$text-white: #fff;
$text-lighter: #cecece;
$text-light: #9b9b9b;
$text-primary: #454545;
$text-dark: #333333;

// Other
$color-background-light: #fafbfc;
$color-hover: #f1f6fb;
$color-background-grey: #ecf0f5;
$color-border-primary: #ebedf8;
$color-divider-primary: #ebedf8;
$color-border-grey: #cdd7e5;
$color-divider-grey: #cdd7e5;
$color-icon: #9398a2;
$color-text: #9398a2;
$color-input-active: #01bcd4;
$color-rating: #ff9901;
