@import '~styles/import';

.enterprise-offer-modal {
  & &__dialog {
    max-width: 838px;
    width: 100%;
  }

  &__background-image {
    position: absolute;
    top: -32px;
    left: -32px;
    right: -32px;
    max-width: none;
    width: calc(100% + 64px);
    height: 420px;
    pointer-events: none;
  }

  &__title {
    margin: 0 auto 72px;
    max-width: 500px;
    font-weight: 600;
    @include font-size(24, 32);
    color: $text-500;

    i {
      color: $info-300;
    }
  }

  &__message {
    margin: 0 auto 40px;
    max-width: 520px;
    @include font-size(18, 30);
    color: $text-500;

    i {
      font-weight: 600;
      color: $secondary-300;
    }
  }

  &__list {
    margin: 0 -8px 32px;

    @include sm {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__item {
    margin: 0 8px 24px;

    @include md-lg {
      flex: 1 1 25%;
    }

    @include sm {
      max-width: 186px;
      flex: 1 1 50%;
    }
  }

  &__image-container {
    margin: 0 auto 8px;
    @include box(57, 48);
    @include font-size(28);
  }

  &__layout-image {
    position: absolute;
    top: 0;
  }

  &__text {
    @include font-size(16, 26);
    color: $text-500;
  }

  i {
    font-style: normal;
  }

  ul {
    list-style: none;
  }
}
