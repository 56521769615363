%space-content {
  content: '\00a0';
}

.space::before {
  @extend %space-content;
}

.space-after::after {
  @extend %space-content;
}
