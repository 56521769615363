/* @components/Modal styles */

//We have a case when old mui modal opens new modal, check InnerOrderConfirmationPopup
.MuiDialog-root.modal {
  z-index: 49 !important;
}

.modal {
  /* Because of MateriaUI styles */
  & &__dialog {
    margin: 8px;

    /* Show close button */
    overflow: visible;

    @include ie() {
      overflow: auto;
    }
  }

  /* Because of MateriaUI styles */
  & &__modal {
    @include min-adaptive(tablet) {
      padding: 32px;
    }

    @include adaptive(mobile) {
      padding: 24px 16px;
    }
  }

  /* Because of MateriaUI styles */
  & &__modal:first-child {
    @include min-adaptive(tablet) {
      padding-top: 32px;
    }

    @include adaptive(mobile) {
      padding-top: 24px;
    }
  }

  &__close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;

    .fa-times {
      font-size: 24px;
    }
  }
}
