/**
 * Outdated styles for compatibility
 */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #2B3151;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

.desktop {
  @include adaptive(tablet) {
    display: none !important;
  }

  @include adaptive(mobile) {
    display: none !important;
  }
}

.desktop-hide {
  @include adaptive(desktop) {
    display: none !important;
  }
}

.tablet {
  @include adaptive(desktop) {
    display: none !important;
  }

  @include adaptive(mobile) {
    display: none !important;
  }
}

.tablet-hide {
  @include adaptive(tablet) {
    display: none !important;
  }
}

.mobile {
  @include adaptive(tablet) {
    display: none !important;
  }

  @include adaptive(desktop) {
    display: none !important;
  }
}

.mobile-hide {
  @include adaptive(mobile) {
    display: none !important;
  }
}
