@import '~styles/variables';

.button {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    &.grey {
      color: $text-primary;
    }
  }  
  
  &.blue {
    font-weight: 600;
    color: $blue-primary;
  }

  &.grey {
    color: $text-light;
    transition: color 0.25s ease; 
  }
}

.playCircle {
  margin-right: 8px;

  &.blue {
    color: $blue-primary;
  }

  &.grey {
    color: $color-icon;
    transition: color 0.25s ease;
  }
}

.duotoneBottomLayer {
  opacity: 0.4;
}