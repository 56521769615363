@import '~styles/variables';
@import '~styles/mixins';

/* Desktop first */
$transition-duration: 0.3s;
$transition: $transition-duration ease;

%text {
  font-size: 14px;
  line-height: 19px;
}

%text-uppercase {
  @extend %text;
  text-transform: uppercase;
}

%text-bold-uppercase {
  @extend %text;
  font-weight: bold;
  text-transform: uppercase;
}

%text-semibold-uppercase {
  @extend %text;
  font-weight: 600;
  text-transform: uppercase;
}

.inner-order-confirmation-modal {
  background-color: rgba($color-pearl, 0.5);

  &__dialog {
    margin: 8px;
    max-width: 766px;
    width: 100%;
    overflow: visible;
  }

  &__modal {
    padding: 0 !important; // material-ui styles
  }
}

.inner-order-confirmation-modal .confirmation-popup,
.inner-order-confirmation-modal .cp {
  @include min-adaptive(tablet) {
    flex-direction: row;
    height: 556px;
  }

  &__container {
    flex-grow: 1;

    @include ie() {
      width: 100%;
    }
  }

  &__toggler {
    background-color: $color-alabaster;

    @include min-adaptive(tablet) {
      padding-top: 30px;
    }

    @include adaptive(mobile) {
      padding-top: 9px;
    }
  }

  &__toggle {
    flex: 1 0 50%;
    @extend %text-bold-uppercase;
    color: $color-graysuit;
    text-align: center;
    border-bottom: 1px solid $color-athensgray;
    cursor: pointer;
    transition: color $transition, border-color $transition;

    @include min-adaptive(tablet) {
      padding: 12px;
    }

    @include adaptive(mobile) {
      padding: 9px;
      font-size: 12px;
      line-height: 16px;
    }

    @include ie() {
      max-width: 50%;
    }
  }

  &__toggle.active {
    color: $color-lochmara;
    border-bottom: 2px solid $color-lochmara;
    cursor: default;
  }

  &__toggle.active .label {
    display: inline-flex;
    color: $color-lochmara;
    background-color: $color-zumthor-outdated;

    @include adaptive(mobile) {
      margin-left: 0;
    }
  }

  footer {
    padding: 16px;
    border-top: 1px solid $color-athensgray;
    background-color: $color-alabaster;
  }

  footer button {
    max-width: 294px;
  }

  &__sub-title {
    margin: 24px 0;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-charcoal;
  }

  &__content {
    overflow-y: auto;

    @include min-adaptive(tablet) {
      padding: 0 48px;
      max-height: 402px;
    }

    @include adaptive(mobile) {
      padding: 0 16px;
    }
  }

  .new-payment-method {
    margin-top: 16px;
  }

  .link-style {
    color: $color-lochmara;
    @extend %text;
  }

  .notification {
    margin-top: 32px;
    padding: 12px;
    font-size: 18px;
    line-height: 25px;
    color: $color-charcoal;
    text-align: center;
    border-radius: 4px;

    /* Not resolved by default */
    border: 1px solid #FBD0D3;
    background-color: #FDECED;
  }

  .notification.resolved {
    border: 1px solid #D1E6C3;
    background-color: #ECF4E6;
  }

  .package-select {
    margin-bottom: 16px;
  }

  .package-select .card-discount {
    @include min-adaptive(tablet) {
      margin-right: 10px;
    }

    @include adaptive(mobile) {
      margin-right: 20px;
    }
  }

  .MuiSelect-root {
    overflow: visible;
  }
}

.inner-order-confirmation-modal .confirmation-popup-aside,
.inner-order-confirmation-modal .cpa {
  &__container {
    flex: 0 0 270px;
    border-right: 1px solid $color-athensgray;
    background-color: $color-zumthor-outdated;

    @include min-adaptive(tablet) {
      padding: 32px;
    }

    @include adaptive(mobile) {
      padding: 16px;
    }

    @include ie() {
      max-width: 270px;
    }
  }

  &__title {
    margin-bottom: 24px;
    color: $color-charcoal;

    @include min-adaptive(tablet) {
      font-size: 24px;
      line-height: 33px;
    }

    @include adaptive(mobile) {
      font-size: 18px;
      line-height: 25px;
      text-align: center;
    }
  }

  h4 {
    /* Total to pay */
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 25px;
    color: $color-charcoal;
  }

  &__amount {
    display: block;
    color: $color-mint;

    @include min-adaptive(tablet) {
      font-size: 42px;
      line-height: 57px;
    }

    @include adaptive(mobile) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  ul li {
    @extend %text;
    color: $color-charcoal;

    @include adaptive(mobile) {
      font-size: 16px;
      line-height: 22px;
    }

    & + * {
      @include min-adaptive(tablet) {
        margin-top: 8px;
      }

      @include adaptive(mobile) {
        margin-top: 16px;
      }
    }

    &.with-margin {
      margin-left: 16px;
    }
  }

  .vat-hint {
    position: relative;
    margin-left: 4px;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
  }

  .vat-hint-info {
    position: absolute;
    left: 0;
    bottom: 100%;
    padding: 8px;
    width: 230px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    transform: translate(-16px, -16px);
    cursor: default;

    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      top: 100%;
      left: 16px;
      margin: auto;
      width: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
    }
  }

  .question-circle path {
    fill: $blue-primary;
    transition: fill 0.3s linear;
  }

  .question-circle:hover path {
    fill: $blue-hover;
  }

  &__total {
    margin-top: 24px;
    @extend %text-bold-uppercase;
    color: $color-charcoal;

    @include adaptive(mobile) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  &__total .margin-left {
    @include min-adaptive(tablet) {
      margin-left: 20px;
    }
  }

  .dots {
    background: transparent url('data:image/gif;base64,R0lGODlhAwABAIAAAKKsvf///yH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS4zLWMwMTEgNjYuMTQ1NjYxLCAyMDEyLzAyLzA2LTE0OjU2OjI3ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M2IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjdGMTREMDU4MkM2MjExRThBRDI2RURGNjNFQjc4MzQyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjdGMTREMDU5MkM2MjExRThBRDI2RURGNjNFQjc4MzQyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6N0YxNEQwNTYyQzYyMTFFOEFEMjZFREY2M0VCNzgzNDIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6N0YxNEQwNTcyQzYyMTFFOEFEMjZFREY2M0VCNzgzNDIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4B//79/Pv6+fj39vX08/Lx8O/u7ezr6uno5+bl5OPi4eDf3t3c29rZ2NfW1dTT0tHQz87NzMvKycjHxsXEw8LBwL++vby7urm4t7a1tLOysbCvrq2sq6qpqKempaSjoqGgn56dnJuamZiXlpWUk5KRkI+OjYyLiomIh4aFhIOCgYB/fn18e3p5eHd2dXRzcnFwb25tbGtqaWhnZmVkY2JhYF9eXVxbWllYV1ZVVFNSUVBPTk1MS0pJSEdGRURDQkFAPz49PDs6OTg3NjU0MzIxMC8uLSwrKikoJyYlJCMiISAfHh0cGxoZGBcWFRQTEhEQDw4NDAsKCQgHBgUEAwIBAAAh+QQAAAAAACwAAAAAAwABAAACAkRSADs=') repeat-x left 14px;

    > * {
      background-color: $color-zumthor-outdated;
    }

    > span:first-child {
      padding-right: 2px;
    }

    > span:last-child {
      padding-left: 2px;
    }
  }

  .total-to-pay {
    margin-bottom: 40px;

    @include adaptive(mobile) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.confirmation-popup .card-form,
.confirmation-popup .cf {
  &__container {
    border: 1px solid transparent;
    border-radius: 4px;
    transition: border-color $transition;

    @include min-adaptive(tablet) {
      margin-bottom: 12px;
      padding: 32px;
      border: 1px solid $color-athensgray;
    }

    @include adaptive(mobile) {
      margin-bottom: 12px;
      padding: 16px 0;
    }

    &.invalid {
      border-color: $color-terracotta;
    }
  }

  &__entry {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }

  &__row {
    margin: 0 -12px;
  }

  &__row > p {
    /* "This card will be saved for future payments" */
    margin-top: 48px;
    padding: 0 12px;
    @extend %text;
  }

  &__row .error-message {
    position: absolute;
    top: -32px;
    padding: 0 12px;
    @extend %text;
  }

  &__wrapper {
    margin: -12px 12px 0;
  }

  &__card {
    margin-bottom: 36px;
  }

  &__expiry, &__cvc {
    flex: 0 0 80px;
  }

  &__checkbox {
    /* "Save card for future payments" checkbox */
    margin: 35px 0 0 12px;
  }
}

.card-wrapper {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $color-charcoal;

  $color-text-bronze: #B85F2D;
  $color-text-silver: #5E687B;
  $color-text-gold: #BC6E06;
  $color-text-platinum: #959DA8;

  .card-icon {
    $background-color-fallback: #9db0c2;

    display: inline-block;
    margin-right: 12px;
    width: 33px;
    height: 21px;
    border-radius: 2px;
    background-color: $background-color-fallback;
  }

  .card-package {
    margin-right: 12px;
    padding: 2px;
    width: 33px;
    height: 21px;
    font-size: 11px;
    line-height: 15px;
    font-weight: bold;
    color: #fff;
    border-radius: 2px;
  }

  .card-package sup {
    font-size: 60%;
  }

  .card-package,
  .card-package--bronze {
    color: $color-text-bronze;
    background-image: linear-gradient(171.47deg, #FEDEB5 -19.71%, #FF9E64 33.18%, #FFA56F 62.9%, #FFC5A1 88.72%, #FFEBD4 115.73%);
  }

  .card-package--silver {
    color: $color-text-silver;
    background-image: linear-gradient(171.75deg, #EBEDEE -17.08%, #B9C3CF 17.48%, #A1B0C0 39.38%, #A2AFC0 76.5%, #C5C8CF 114.75%);
  }

  .card-package--gold {
    color: $color-text-gold;
    background-image: linear-gradient(174deg, #FFE568 -23.12%, #FFD969 1%, #FFBE02 32.26%, #FBBA43 68.14%, #FFD507 113.57%);
  }

  .card-package--platinum {
    color: $color-text-platinum;
    background-image: linear-gradient(171.75deg, #F2F2F3 -17.08%, #E5E5E6 17.48%, #D8D9DA 48.39%, #D8D9DA 68.91%, #BDBEC1 102.2%);
  }

  .card-default {
    margin-left: 24px;
    font-size: 11px;
    line-height: 15px;
    font-weight: bold;
    color: $color-graysuit;
    text-transform: uppercase;

    & svg {
      margin-right: 5px;
    }
  }

  .amount {
    @include min-adaptive(tablet) {
      font-size: 18px;
      line-height: 25px;
    }

    @include adaptive(mobile) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .save {
    font-size: 11px;
    line-height: 15px;
    font-weight: bold;
    color: $color-terracotta;
  }

  .card-discount {
    @include adaptive(mobile) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.label {
  .inner-order-confirmation-modal .confirmation-popup &,
  .card-wrapper & {
    display: inline-flex;
    margin-left: 8px;
    padding: 0 4px;
    align-items: center;
    height: 20px;
    line-height: 1;
    color: #fff;
    border-radius: 3px;
    @extend %text-bold-uppercase;
    transition: color $transition, background-color $transition;

    @include adaptive(mobile) {
      display: none;
    }
  }
}
