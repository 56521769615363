.please-wait-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .wait-message {
    text-align: center;
  }
}