@import '~styles/mixins';
@import '~styles/variables';

$upsell-mobile-width: 304px;
$upsell-mobile-half-width: calc(#{$upsell-mobile-width} / 2);
$upsell-tablet-width: 720px;
$upsell-tablet-half-width: calc(#{$upsell-tablet-width} / 2);
$upsell-desktop-width: 754px;
$upsell-desktop-half-width: calc(#{$upsell-desktop-width} / 2);

.upsell {

  .MuiBackdrop-root {
    @include max-adaptive(mobile) {
      background: linear-gradient(0deg, rgba(39, 151, 220, 0.9) 0%, rgba(10, 90, 144, 0.9) 100%);
    }
  }

  &__dialog {
    overflow: visible;
    max-width: $upsell-mobile-width;

    @include adaptive(mobile) {
      margin: 30px 8px;
    }

    @include min-adaptive(tablet) {
      max-width: $upsell-tablet-width;
    }

    @include min-adaptive(desktop) {
      max-width: $upsell-desktop-width;
    }
  }

  &__modal, &__modal:first-child {
    padding: 0 !important; // Because of MaterialUI styles
  }

  &__container {
    position: relative;
    padding: 18px 8px 28px;

    @include min-adaptive(tablet) {
      padding: 34px 40px 37px;
    }

    @include min-adaptive(desktop) {
      padding: 42px 40px 36px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__rocket {
    margin-bottom: 10px;
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;

    @include min-adaptive(tablet) {
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 24px;
      color: #454545;
    }
  }

  &__discount-container {
    position: relative;
    padding: 23px;
    max-width: 264px;
    margin-bottom: 32px;

    @include min-adaptive(tablet) {
      width: 100%;
      max-width: none;
      padding: 34px 0 37px;
    }
  }

  &__discount-container img {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__discount-container img:last-of-type {
    @include min-adaptive(tablet) {
      left: auto;
      right: 0;
      top: 105px;
    }
  }

  &__discount-container p {
    position: relative;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    color: #095C88;

    @include min-adaptive(tablet) {
      font-size: 42px;
      font-weight: 600;
      line-height: 57px;
    }
  }

  &__discount-container p:last-of-type {
    margin-top: 8px;

    @include min-adaptive(tablet) {
      font-size: 30px;
      line-height: 41px;
    }
  }

  &__discount-container p span {
    color: #ED715C;
  }

  &__description {
    line-height: 24px;
    max-width: 256px;

    @include min-adaptive(tablet) {
      font-size: 16px;
      line-height: 26px;
    }
  }

  &__regular {
    margin-bottom: 4px;
    font-size: 18px;
    letter-spacing: -0.2px;
    color: #9398A2;
  }

  &__button {
    margin: 30px auto 12px;
    width: 100%;
    font-size: 16px;
    text-transform: uppercase;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.24);

    @include min-adaptive(tablet) {
      max-width: 394px;
      width: 394px;
      margin-bottom: 36px;
    }
  }

  &__cancel {
    font-size: 14px;
    font-weight: bold;
    color: #9398A2;
    opacity: 0.5;
    text-transform: uppercase;
    margin: 0 auto -10px;
    padding: 10px;
    background: none;
    border: none;
    outline: none;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
  }

  &__close-button {
    position: absolute;
    top: -32px;
    right: calc(50% - #{$upsell-mobile-half-width} + 8px);
    opacity: 0.7;
    z-index: 1;
    transition: opacity 0.2s ease;
    cursor: pointer;

    @include min-adaptive(tablet) {
      right: calc(50% - #{$upsell-tablet-half-width} + 8px);
    }

    @include min-adaptive(desktop) {
      right: calc(50% - #{$upsell-desktop-half-width} + 36px);
    }

    &:hover {
      opacity: 1;
    }
  }

  &__close-button &__close-icon {
    width: 14px;
  }
}

.upsell-credit {
  &__container {
    padding: 50px 40px 15px;
    max-width: 600px;
    width: 100%;
  }

  &__title {
    margin: 0 auto 35px;
    font-weight: normal;

    @include ie() {
      max-width: 100%;
    }
  }

  &__regular {
    padding: 0 17px;
    margin-bottom: 18px;
    font-size: 16px;
    text-align: center;
    line-height: 24px;;

    @include ie() {
      max-width: 100%;
    }
  }

  &__background-image {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  &__cancel {
    display: flex;
    margin: 0 auto;
    padding: 10px;
    font-size: 14px;
    background: none;
    border: none;
    outline: none;
  }
}

.referral {
  &__container {
    max-width: 530px;
    width: 100%;
  }

  &__title {
    margin: 20px auto 40px;
    max-width: 100%; // IE
    font-weight: normal;
  }

  &__subtitle {
    margin-bottom: 16px;
    max-width: 100%; // IE
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }

  &__regular {
    max-width: 100%; // IE
    font-size: 14px;
    line-height: 1.57;
    text-align: center;
    opacity: 0.9;
  }
}
