@import 'styles/new-variables';

.iconSize-s {
  width: 16px;
  height: 16px;
  
  // Fallback size in case there is no icon config for this size
  font-size: 14px;
}

.iconSize-m {
  width: 24px;
  height: 24px;

  // Fallback size in case there is no icon config for this size
  font-size: 18px;
}

.iconSize-l {
  width: 32px;
  height: 32px;

  // Fallback size in case there is no icon config for this size
  font-size: 24px;
}

.disabled {
  color: $text-200;
}
