@keyframes theme-loading {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: none;
  }
}

.theme-loader {
  &__container {
    @include box(72);
    border-radius: 50%;
    background-color: $color-white;
  }

  &__container.animated {
    animation: theme-loading 3s ease-in-out infinite;
  }
}

.theme-loader-modal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  background-color: $other-100;
}
