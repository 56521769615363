.wrapper {
  position: relative;
  padding-bottom: 54%;
  height: 0;
  overflow: hidden;
  width: 100%;

  &>iframe {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}