@import 'styles/new-variables';

.button {
  display: flex;
  flex-grow: 0;
  height: fit-content;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid transparent;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease;
  transition-duration: 200ms;
  position: relative;
  gap: 4px;
  min-width: fit-content;

  .iconContainer {
    width: 24px;
    height: 24px;
    font-size: inherit; //TODO remove once we have global icon config
  }

  .dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // Sizes
  &.s {
    @apply font-brand-l3;
    height: 40px;
    padding-inline: 23px;
  }

  &.m {
    @apply font-brand-l2;
    height: 48px;
    padding-inline: 31px;
  }

  &.l {
    @apply font-brand-l1;
    height: 56px;
    padding-inline: 39px;
  }

  // Colors
  &.primary {
    background-color: $primary-300;
    border-color: $primary-300;
    color: $text-100;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $primary-400;
      border-color: $primary-400;
    }
  }

  &.primary-outlined {
    color: $primary-300;
    border-color: $primary-300;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $primary-400;
      border-color: $primary-400;
      color: $text-100
    }
  }

  &.secondary {
    color: $text-100;
    background-color: $secondary-300;
    border-color: $secondary-300;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $secondary-400;
      border-color: $secondary-400;
    }
  }

  &.secondary-navy {
    color: $text-100;
    background-color: $text-600;
    border-color: $text-600;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $text-100;
      color: $text-600;
    }
  }

  &.text {
    padding: 0;
    height: 24px;
    border: none;
    color: $primary-300;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      color: $primary-400;
    }

    &.disabled {
      opacity: 1;
      color: $text-200;
    }
  }

  &.error {
    background-color: $error-300;
    border-color: $error-300;
    color: $text-100;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $error-300;
      border-color: $error-300;
    }
  }

  &.white-outlined {
    border-color: $text-100;
    color: $text-100;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $primary-100;
      border-color: $primary-100;
      color: $primary-400;
    }
  }

  &.grey-outlined {
    border-color: $text-200;
    color: $text-400;

    &:not(.disabled):hover,
    &:not(.disabled):active {
      background-color: $other-100;
    }
  }

  // States
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.loading {
    cursor: not-allowed;

    span {
      opacity: 0;
    }

    .iconContainer {
      opacity: 0;
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: none;
  transition-property: color, background-color;
  transition-timing-function: ease;
  transition-duration: 200ms;
  position: relative;

  &.s, &.s .iconContainer {
    font-size: 18px;
    width: 24px;
    height: 24px;
  }

  &.m, &.m .iconContainer {
    font-size: 21px;
    width: 32px;
    height: 32px;
  }

  &.l, &.l .iconContainer {
    font-size: 24px;
    width: 40px;
    height: 40px;
  }

  .dots {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  // Colors
  color: $primary-300;

  &:not(.disabled):hover,
  &:not(.disabled):active {
    color: $primary-400;
  }

  // States
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &.loading {
    cursor: not-allowed;

    .iconContainer {
      opacity: 0;
    }
  }
}

.link {
  color: $primary-300;
  display: inline-flex;
  position: relative;
  min-width: 0;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 200ms;
  height: fit-content;
  box-sizing: border-box;

  .iconContainer {
    width: 24px;
    height: 24px;
    font-size: inherit; //TODO remove once we have global icon config
  }
  
  // Sizes
  &.s {
    @apply font-brand-l3;
  }
  &.m {
    @apply font-brand-l2;
  }

  // State 
  &:hover {
    color: $primary-400;
    box-shadow: 0 0.5px 0 $primary-400;
  }
}
