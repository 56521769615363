%notification {
  &-success {
    color: $secondary-400;
    border: 1px solid $secondary-200;
    background-color: $secondary-100;
  }

  &-warning {
    color: $warning-300;
    border: 1px solid $warning-200;
    background-color: $warning-100;
  }

  &-error {
    color: $error-300;
    border: 1px solid $error-200;
    background-color: $error-100;
  }

  &-info {
    color: $info-300;
    border: 1px solid $info-200;
    background-color: $info-100;
  }
}

.notification {
  &__container {
    position: relative;
    padding: 8px 16px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
  }

  &__close {
    position: absolute;
    top: 4px;
    right: 0;
    font-size: 16px;
  }

  &.close {
    padding-right: 32px;
  }

  &.success {
    @extend %notification-success;
  }

  &.warning {
    @extend %notification-warning;
  }

  &.error {
    @extend %notification-error;
  }

  &.info {
    @extend %notification-info;
  }

  b,
  strong {
    font-weight: 600;
  }

  a {
    text-decoration: underline;
  }
}

.notification.arrow {
  $arrow-intend: -5px;

  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    pointer-events: none;
  }

  &.top::before {
    top: $arrow-intend;
    right: 0;
    left: 0;
    margin: auto;
    border-right: 0;
    border-bottom: 0;
  }

  &.right::before {
    top: 0;
    right: $arrow-intend;
    bottom: 0;
    margin: auto;
    border-bottom: 0;
    border-left: 0;
  }

  &.bottom::before {
    right: 0;
    bottom: $arrow-intend;
    left: 0;
    margin: auto;
    border-top: 0;
    border-left: 0;
  }

  &.left::before {
    top: 0;
    bottom: 0;
    left: $arrow-intend;
    margin: auto;
    border-top: 0;
    border-right: 0;
  }

  &.success::before {
    @extend %notification-success;
  }

  &.warning::before {
    @extend %notification-warning;
  }

  &.error::before {
    @extend %notification-error;
  }

  &.info::before {
    @extend %notification-info;
  }
}
