@import 'src/styles/_new-variables';
@import 'src/styles/_mixins';

.cell {
  @apply font-brand-b3;
  padding-left: 8px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  color: $text-500;
  border-bottom: 1px solid $other-300;
  min-height: 48px;
  height: 100%;
  transition: background-color .2s ease;
}

.th {
  @apply truncate text-text-300 font-brand-ov;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  height: 40px;
  background-color: $other-100;
  padding-left: 8px;
  padding-right: 16px;
  border-bottom: 1px solid $other-200;
  cursor: default;
}

.thTextContainer {
  margin-left: -5px;
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 5px;
  border-radius: 2px;
  max-width: max-content;
}

.thTextContainer.sortable {
  cursor: pointer;
  @include transition();

  .icon {
    @include transition();
    opacity: 0;
    margin-left: 4px;
  }
}

.thTextContainer:hover .icon {
  opacity: 1;
  color: $text-500;
}

.thTextContainer.sortable:hover {
  background-color: $other-200;
}

.thTextContainer.sorted .icon {
  color: $primary-400;
  opacity: 1;
}

.table {
  display: grid;
}
