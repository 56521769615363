@import 'styles/new-variables';

@keyframes makeLine {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.active {
  &::after {
    position: absolute;
    content: '';
    height: 2px;
    bottom: -2px;
    border-radius: 2px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background-color: $primary-300;
    animation: makeLine .3s ease-in-out;
  }
}
