@import 'styles/new-variables';

.wrapper {
  @apply font-brand-b3;
  display: block;
  margin: 0;
  color: $text-500;
  user-select: none;
  cursor: pointer;
}

.checkmark {
  color: $other-500;
  font-size: 22px;
  width: 24px;
  height: 24px;
}

.checked {
  .checkmark {
    color: $secondary-300;
  }
}

.disabled {
  .checkmark {
    color: $other-500;
  }

  .text {
    color: $text-300;
  }
}

.checked.disabled {
  .checkmark {
    color: $other-500;
  }

  .text {
    color: $text-400;
  }
}

.titleIcon {
  color: $text-300;
  pointer-events: auto;
}

.hintTooltip {
  max-width: 230px;
  padding: 8px 12px;
}
