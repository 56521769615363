@import 'src/styles/_new-variables';

.container {
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: -4px;
  max-height: 160px;
  max-width: max-content;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  right: 0;
}


.item {
  color: $text-500;
  font-size: 13px;
  height: 32px;
  padding: 0 8px;
  text-align: start;

  &:active,
  &:hover {
    background-color: $text-200;
    color: $primary-400;
  }
}
