@import 'styles/new-variables';

.switcher.switcher {
  @include transition();

  background-color: $other-500;
  border-radius: 12px;
  height: 24px;
  position: relative;
  width: 42px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.on {
    background-color: $primary-300;
  }

  span {
    background-color: $text-100;
    border-radius: 50%;
    height: 20px;
    position: absolute;
    top: 2px;
    width: 20px;
    @include transition();

    &.on {
      right: 2px;
    }

    &.off {
      left: 2px;
    }
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin: 0;
  color: $text-500;
  user-select: none;
  cursor: pointer;
}

.disabled {
  .text {
    color: $text-300;
  }
}

.checked.disabled {
  .text {
    color: $text-400;
  }
}
