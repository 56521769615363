/**
 * Usage:
 *
 * @include box(24) => { width: 24px; height: 24px };
 * @include box(24, 48) => { width: 24px; height: 48px };
 */
@mixin box($width, $height: $width) {
  width: $width * 1px;
  height: $height * 1px;
}
