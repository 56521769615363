@import 'styles/new-variables';

.wrapper {
  display: block;
  width: 100%;
  pointer-events: none;
  margin: 0;
  width: 264px;
}

.inputsWrapper {
  display: flex;
  align-items: center;
  pointer-events: auto;
  position: relative;
  color: $text-500;
  border: 1px solid $other-400;
  border-radius: 4px;
  background-color: $other-50;
  @include transition();
}

.inputs {
  font-size: inherit;
  height: 46px;
  background-color: transparent;

  &::placeholder {
    color: $other-600;
  }

  &:autofill, &:-internal-autofill-selected {
    background-color: transparent !important;
  }
}

.codeInput {
  composes: inputs;
  margin: 0 4px;
  width: 50px;
}

.numberInput {
  composes: inputs;
  padding: 0 16px 0 8px;
  min-width: 155px;
  border-left: 1px solid $other-400;
  @include transition();
}

.codeInputWrapper {
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-left: -1px;
}

.children {
  @include center;
  font-size: 10px;
}

.children > * {
  @include box(16);
}

.error {
  .inputsWrapper {
    background-color: #FFFBFB;
    border-color: $error-300;
    color: $text-500;
  }

  .numberInput {
    border-color: $error-300;
  }
}

.wrapper:focus-within {
  .inputsWrapper {
    border-color: #61A8EA;
    background-color: #F5FAFF;
    color: $text-500;

    .codeInput::placeholder, .numberInput::placeholder {
      color: $other-600;
    }
  }

  .numberInput {
    border-color: #61A8EA;
  }
} 

.disabled {
  .inputsWrapper {
    color: $text-200;
    border-color: $other-300;
    background-color: $other-100;
    cursor: not-allowed;
  }

  .numberInput {
    border-color: $other-300;
  }

  .codeInput, .numberInput {
    cursor: not-allowed;
  }

  .codeInput::placeholder, .numberInput::placeholder {
    color: $text-200;
  }

  .codeInput::selection, .numberInput::selection {
    background-color: transparent;
    color: inherit;
  }

  .children > * {
    pointer-events: none;
  }
}
