@import '~styles/import';

.cookie-settings {
  &__wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    // should be lower than a modal
    z-index: 49;
    background-color: $color-white;
    box-shadow: 0px -3px 16px rgba(30, 40, 96, 0.1);
  }

  &__container {
    max-width: 1280px;
    @include margin-auto;
    padding: 16px;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 600;
    @include font-size(20, 28);
    color: $text-600;
  }

  &__content {
    @include sm {
      flex-direction: column;
    }
  }

  &__text {
    max-width: 894px;
    @include font-size(16, 26);
    color: $text-400;
  }

  &__button-container {
    @include md-lg {
      margin-left: 24px;
    }

    @include md {
      flex-direction: column;
      align-items: flex-end;
    }

    @include sm {
      margin-top: 24px;
    }
  }

  .link {
    display: inline-block;
    color: $primary-400;
  }

  .tf-button {
    @include sm-md {
      order: 1;
    }

    @include md {
      margin-bottom: 8px;
    }
  }

  button.primary-300 {
    padding: 8px 0;

    @include lg {
      margin-right: 24px;
    }

    @include sm-md {
      order: 2;
    }

    @include sm {
      margin-left: 24px;
    }
  }
}
